import React from 'react'

const UtilityContent = () => {
    return (
        <div className='UtilityContent'>
            <p><span>***$7 flat rate shipping </span>
                for all orders in the usa***
            </p>
        </div>
    )
}

export default UtilityContent